import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { Influencer, Pool } from 'src/types';
import {
  getCurrentInfluencersId,
  getNormalizedInfluencers,
} from 'src/utils/reducer';

export type State = {
  data?: Pool[];
  count: number;
  detail: Pool;
  influencerSearch?: string;
  searchSettings?: {
    searchWord: string;
    limitElements: number;
    currentPage: number;
  };
  poolSearchSettings?: {
    poolId: string;
    searchWord: string;
    limitElements: number;
    currentPage: number;
  };
  poolInfluencerCount?: number;
  selectedInfluencers?: string[];
};

const initialState: State = {
  data: [],
  count: 0,
  searchSettings: {
    searchWord: '',
    limitElements: 50,
    currentPage: 1,
  },
  poolSearchSettings: {
    poolId: '',
    searchWord: '',
    limitElements: 50,
    currentPage: 1,
  },
  influencerSearch: undefined,
  detail: {
    name: '',
    description: '',
    orgId: '',
    createdBy: '',
    updatedBy: '',
    createdAt: '',
    updatedAt: '',
    isActive: false,
    _id: '',
    orgName: '',
  },
  selectedInfluencers: undefined,
  poolInfluencerCount: 0,
};

const reducer = reducerWithInitialState<State>(initialState)
  .cases(
    [actions.api.pool.getPools.done],
    (state, payload): State => ({
      ...state,
      data: payload.result.result,
      count: payload.result.count,
    })
  )
  .cases(
    [actions.api.pool.getPoolDetail.done],
    (state, payload): State => ({
      ...state,
      detail: {
        ...payload.result,
        influencerIds: getNormalizedInfluencers(
          payload.result.influencerIds as unknown as Influencer[]
        ),
      },
    })
  )
  .cases(
    [actions.ui.pool.setSearchSettings],
    (state, payload): State => ({
      ...state,
      searchSettings: {
        ...state.searchSettings,
        ...payload,
      },
    })
  )
  .cases(
    [actions.ui.pool.setPoolSearchSettings],
    (state, payload): State => ({
      ...state,
      poolSearchSettings: {
        ...state.searchSettings,
        ...payload,
      },
    })
  )
  .cases(
    [actions.ui.pool.setPoolInfluencerSearch],
    (state, payload): State => ({
      ...state,
      influencerSearch: payload,
    })
  )
  .cases(
    [actions.ui.pool.setPoolInfluencerSearch],
    (state, payload): State => ({
      ...state,
      influencerSearch: payload,
    })
  )
  .cases(
    [actions.ui.pool.setSelectedInfluencers],
    (state, payload): State => ({
      ...state,
      selectedInfluencers: getCurrentInfluencersId({
        influencers: payload.influencers,
        state: state.selectedInfluencers,
      }),
    })
  );

export const pool = { reducer, initialState };
