import { put, select, takeEvery } from 'redux-saga/effects';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import { InfluencerSearchSettings, SearchSettings } from 'src/types';

function* getPool() {
  const { limitElements, searchWord, currentPage }: SearchSettings =
    yield select((state: ReduxState) => state.pool.searchSettings);

  yield put(
    actions.api.pool.getPools.started({
      limit: limitElements,
      search: searchWord,
      page: currentPage,
    })
  );
}

function* getPoolDetail() {
  const { pool, search, page, limit } = yield select((state: ReduxState) => ({
    pool: state.pool.poolSearchSettings?.poolId,
    search: state.pool.poolSearchSettings?.searchWord,
    page: state.pool.poolSearchSettings?.currentPage,
    limit: state.pool.poolSearchSettings?.limitElements,
  }));

  if (pool) {
    yield put(
      actions.api.pool.getPoolDetail.started({
        search: search ?? undefined,
        page: page ?? 1,
        limit: limit ?? 50,
        extra: {
          id: pool,
        },
      })
    );
  }
}

function* closeModal() {
  yield put(actions.ui.modal.hide());
}

function* resetInfluencerSearchSettings() {
  yield put(
    actions.ui.influencers.setSelectedInfluencers({
      influencers: [],
    })
  );
  const {
    limitElements,
    searchWord,
    currentPage,
    isInstagram,
    isTiktok,
    engagement_status,
    selectedTags,
    notInSelectedTags,
    socialPlatform,
    minFollowers,
    maxFollowers,
    statusFilter,
    selectedOrganization,
    notInSelectedOrganization,
    sort,
  }: InfluencerSearchSettings = yield select(
    (state: ReduxState) => state.influencers.influencerSearchSettings
  );

  yield put(
    actions.api.influencers.getInfluencers.started({
      limit: limitElements,
      search: searchWord,
      page: currentPage,
      isInstagram,
      isTiktok,
      engagement_status,
      selectedTags: selectedTags?.map((x) => x.id)?.toString(),
      notInSelectedTags: notInSelectedTags?.map((x) => x.id)?.toString(),
      notInSelectedOrganization:
        notInSelectedOrganization && notInSelectedOrganization.id,
      socialPlatform: socialPlatform?.toString(),
      minFollowers,
      maxFollowers,
      statusFilter,
      selectedOrganization: selectedOrganization && selectedOrganization.id,
      sort: JSON.stringify(sort),
    })
  );
}

export function* poolSaga(): Generator {
  yield takeEvery(
    [actions.ui.pool.setSearchSettings, actions.api.pool.createPool.done],
    getPool
  );
  yield takeEvery(
    [
      actions.ui.pool.setPoolSearchSettings,
      actions.api.pool.createPool.done,
      actions.ui.pool.setPoolInfluencerSearch,
    ],
    getPoolDetail
  );
  yield takeEvery(
    [actions.api.pool.createPool.done, actions.api.pool.assignPool.done],
    closeModal
  );
  yield takeEvery(
    actions.api.pool.assignPool.done,
    resetInfluencerSearchSettings
  );
}
