import { delay, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { actions } from 'src/actions';

function* handleActions(action: Action<{} | void>, type: 'add' | 'remove') {
  const actionsType = action.type;

  const handler =
    type === 'add'
      ? actions.ui.currentActiveActions.setActions
      : actions.ui.currentActiveActions.removeActions;
  yield put(handler({ actions: [actionsType] }));
}

export function* currentActiveActionsSaga(): Generator {
  yield takeEvery(
    [
      actions.api.pool.getPoolDetail.started,
      actions.api.pool.getPoolDetail.done,
      actions.api.influencers.getInfluencers.started,
      actions.api.influencers.getInfluencers.done,
    ],
    (action) => handleActions(action, 'add')
  );

  yield takeEvery(
    [
      actions.api.pool.getPoolDetail.failed,
      actions.api.pool.getPoolDetail.done,
      actions.api.influencers.getInfluencers.failed,
      actions.api.influencers.getInfluencers.done,
    ],
    function* (action) {
      yield delay(300);
      yield handleActions(action, 'remove');
    }
  );
}
