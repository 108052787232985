export const getInstagramLogin = (link = '') =>
  new RegExp(
    /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?([a-zA-Z0-9._-]+)\/?$/
  ).exec(link?.trim())?.[1];

export const getTiktokLogin = (link = '') =>
  new RegExp(/(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9._-]+)/).exec(
    link?.trim()
  )?.[1] || new RegExp(/[^\/]+(?=\/*$)/).exec(link?.trim())?.[0];

export const maskUsername = (value = '') => {
  try {
    const atIndex: number = value.indexOf('@');
    if (value.length < 5) {
      return `${value.slice(0, atIndex + 3)}${'•'.repeat(
        value.length - atIndex
      )}`;
    } else {
      const atIndex: number = value.indexOf('@');
      const maskedEmail = `${value.slice(0, atIndex + 3)}${'•'.repeat(
        value.length - atIndex - (value.length - 3)
      )}${value.slice(-2)}`;
      return maskedEmail;
    }
  } catch (error) {
    return value;
  }
};
